import { getClientRequestUrl } from '../client.mjs';
import { determineEnvironment } from '@cultureamp/frontend-env';
var FETCH_ERRORS;
(function (FETCH_ERRORS) {
  FETCH_ERRORS[FETCH_ERRORS["NETWORK_FAILURE"] = 523] = "NETWORK_FAILURE";
})(FETCH_ERRORS || (FETCH_ERRORS = {}));
const getAllRequestsInFlight = () => {
  const win = window;
  if (!win.requestsInFlight) {
    win.requestsInFlight = new Map();
  }
  return win.requestsInFlight;
};
const getRequestInFlight = (url, meta) => {
  var _a;
  if (typeof window === "undefined") {
    return undefined;
  }
  const href = getClientRequestUrl(url, meta);
  const requestsInFlight = getAllRequestsInFlight();
  return {
    key: href,
    value: (_a = requestsInFlight.get(href)) === null || _a === void 0 ? void 0 : _a.finally(() => {
      requestsInFlight.delete(href);
    })
  };
};
const setRequestInFlight = (url, options) => {
  var _a;
  const fetchFun = (_a = options.fetch) !== null && _a !== void 0 ? _a : globalThis.fetch;
  const href = getClientRequestUrl(url, options);
  const fetchPromise = fetchFun(url, options);
  // in test environments, we might have requests set to a loading state on purpose
  // in those scenarios, we don't want to dedupe the requests to avoid tests failing because of previous promises not resolving
  const {
    realm,
    kind
  } = determineEnvironment();
  if (realm === "test" && kind !== "jest") return fetchPromise;
  const requestsInFlight = getAllRequestsInFlight();
  requestsInFlight.set(href, fetchPromise);
  return fetchPromise.finally(() => requestsInFlight.delete(href));
};
const dedupeFetch = (url, options) => {
  var _a, _b;
  return (_b = (_a = getRequestInFlight(url, options)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : setRequestInFlight(url, options);
};
const fetchWithPreload = async (ctx, next) => {
  var _a, _b, _c, _d, _e;
  const fetchFunc = (_a = ctx.options.fetch) !== null && _a !== void 0 ? _a : globalThis.fetch;
  try {
    const url = ctx.input.toString();
    const isGetMethod = !((_b = ctx.options) === null || _b === void 0 ? void 0 : _b.method) || ((_d = (_c = ctx.options) === null || _c === void 0 ? void 0 : _c.method) === null || _d === void 0 ? void 0 : _d.toLowerCase()) === "get";
    // we only support deduping and preload for GET method at the moment
    const request = isGetMethod ? dedupeFetch(url, ctx.options) : fetchFunc(ctx.input, ctx.options);
    ctx.response = await request;
  } catch (err) {
    // error is network error like ECONNRESET ... (https://nodejs.org/api/errors.html#errors_common_system_errors)
    // so we want to create a simple response for it so we can manage it consistently with response errors
    ctx.response = new Response(JSON.stringify(err), {
      status: FETCH_ERRORS.NETWORK_FAILURE,
      statusText: (_e = err.code) !== null && _e !== void 0 ? _e : "ECONNRESET"
    });
  }
  return next();
};
export { FETCH_ERRORS, fetchWithPreload, getAllRequestsInFlight };
